$font35: 35px;
$font30: 30px;
$font32: 32px;
$font18: 18px;
$font16: 16px;
$font15: 15px;
$font14: 14px;
$font13: 13px;
$shadow: 0 1px 9px 0 #acacac;
$primary-dark: #000230;
$primary-light: #6e6e6e;
$primary-lighter: #969696;
$primary: #029bc2;
$white: #fff;
