@import './utils/variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

a {
    text-decoration: none;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #dadada;
}

.heading2 {
    font-size: $font35;
    color: $primary-dark;
    font-weight: 600;
}
.heading3 {
    font-size: $font32;
    color: $primary-dark;
}
.heading4 {
    font-size: $font30;
    color: $primary-dark;
    font-weight: 600;
}
.heading5 {
    font-size: $font18;
    color: $primary-dark;
}
.paragraph1 {
    font-size: $font16;
    color: $primary-light;
    font-weight: 400;
}
.paragraph2 {
    font-size: $font15;
    color: $primary-light;
    font-weight: 500;
}
.paragraph3 {
    font-size: $font14;
    color: $primary-light;
}
.paragraph4 {
    font-size: $font13;
    color: $primary-dark;
    font-weight: 300;
}

.button {
    padding: 13px 15px;
    font-size: $font16;
    font-weight: 400;
    color: $white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    cursor: pointer;

    &:hover {
        scale: 0.95;
    }
}
.button-secondary {
    font-size: 14px;
    line-height: 24px;
    width: 117px;
    height: 32px;
    border-radius: 5px;
    background-color: #0080b0;
    color: white;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: none;
    cursor: pointer;

    &:hover {
        scale: 0.95;
    }
}
.flex {
    display: flex;
}
.aic {
    align-items: center;
}
.jcsb {
    justify-content: space-between;
}
.w-full {
    width: 100%;
}
.grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.grid4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.container-Xl {
    max-width: 1550px;
    padding: 0 9px;
    margin: 0 auto;
    width: 100%;
}
.container-Xl {
    max-width: 1550px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    width: 100%;
}
.container {
    max-width: 1300px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}
.border {
    border: 1px solid red;
}
.breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-weight: 300;
    color: #000;
    margin-top: 20px;
    margin-bottom: 40px;
    span {
        text-decoration: underline;
        cursor: pointer;
    }
    small {
        font-size: 11px;
        margin-top: 2px;
    }
}

.space100 {
    width: 100px;
    height: 100px;
}
